.about__container {
  width: 100%;
  padding-top: 5rem;
  display: flex;
  min-width: 0;
  position: relative;
  overflow: hidden;
}

p {
  margin-bottom: 1rem;
}

.about__me {
  margin-left: auto;
  max-width: 40%;
}

.about__me-image {
  border-radius: 0.5rem;
  overflow: hidden;
  object-fit: contain;
  transition: var(--transition);
  filter: drop-shadow(0.1rem 0.1rem 0.2rem var(--color-primary));
}

.about__me-image:hover {
  -webkit-transform: translate(-0.6rem, -0.4rem);
  transform: translate(-0.6rem, -0.4rem);
  transition: var(--transition);
  -webkit-filter: brightness(120%);
  filter: drop-shadow(0.5rem 0.5rem 0.5rem var(--color-primary));
}

.about__content {
  min-width: 0;
  overflow: hidden;
  max-width: 50%;
  color: var(--color-light);
}
.about__link{
    font-size: 1.5rem;
}


.about__content p {
  max-width: 100%;
  color: var(--color-light);
}

@media screen and (max-width: 1024px) {
    .genearlP{
        font-size: 1.2rem;
    }
    .about__link{
        font-size: 1.2rem;
    }
}
@media screen and (max-width: 600px) {
  .about__container {
    padding-top: 4.5rem;
    display: flex;
    flex-direction: column-reverse;
  }
  .about__link{
    font-size: 1rem;
}
  .genearlP{
    font-size: 1rem;
}

  .about__content {
    max-width: 70%;
  }
  .about__me {
    position: relative;
    margin-bottom: 2rem;
    max-width: 55%;
  }
}
