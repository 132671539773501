@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #191827;
  --color-bg-variant: #051937;
  --color-primary: #459ff4;
  --color-primary-transparent: rgba(var(--color-primary), 0.4);
  /* --color-primary: #ccd6f6; */
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #ccd6f6;
  --color-light: rgba(77, 181, 219, 0.518);

  --transition: all 600ms ease;

  --container-width-lg: 63%;
  --container-width-md: 84%;
  --container-width-sm: 92%;

  --primary-font: "Montserrat", sans-serif;
  --seconary-font: "Montserrat", sans-serif;
}
strong {
  font-weight: normal;
  color: var(--color-primary);
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: var(--primary-font);
  background: var(--color-bg);
  color: var(--color-white);
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.7;
  
}

.backdrop {
  position: absolute;
  z-index: -1;
}
.background__image {
  position: fixed;
  opacity: 0.35;
}
.background__image2 {
  position: fixed;
  opacity: 0.1;
  object-position: 4vh 4vh;
}

.container {
  min-width: 0;
  position: relative;
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h2 {
  font-weight: 700;
  font-size: 4rem;
  line-height: 5rem;
}



section {
  position: relative;
  margin-bottom: 25vw;
  padding-top: 7vw;
  /* background: black; */
}

.container__header {
  display: flex;
}

h5 {
  font-weight: 500;
  font-size: 2.5rem;
  text-align: left;
  color: var(--color-white);
}

/* section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
} */

.text-light {
  color: var(--color-light);
  transition: var(--transition);
}

a {
  font-size: 1.3rem;
  color: var(--color-white);
  transition: var(--transition);
}

a:hover {
  cursor: pointer;
  color: var(--color-primary);
  scale: 1.04;
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
  transition: var(--transition);
}

p{
  font-size: 1.5rem;
}

/*===============Responsiveness ===============*/

@media screen and (max-width: 1024px) {
  /* transition: var(--transition); */
  .container {
    width: var(--container-width-md);
  }
  section {
    margin-bottom: 15vw;
    padding-top: 7vw;
    margin-top: 6rem;
  }
  p{
    font-size: 1.2rem;
  }
  h2 {
    line-height: 4rem;
    font-size: 3rem;
  }
  h5 {
    font-size: 2rem;
  }
  a {
    font-size: 1.2rem;
  }
  small {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
  section {
    margin-bottom: 2rem;
  }
  h2 {
    line-height: 3.5rem;
    font-size: 2.7rem;
  }
  h5 {
    font-size: 1.7rem;
  }
  p{
    font-size: 0.9rem;
  }
  a {
    font-size: 0.9rem;
  }
  small {
    font-size: 0.6rem;
  }
}
