.container__contact{
    padding-top: 5rem;
    padding-bottom: 0;
    margin-bottom: 0;
   
}

.contact__body{
    padding: 3rem;
    transition: var(--transition);
    max-width: 60%;
    color: var(--color-light); 
    font-size: 1.1rem;
    box-shadow: 0rem 0.1rem 0rem  var(--color-primary) ;
}
.contact__body>p{
    padding-bottom: 2rem;
}


.contact__body:hover {
    transition: var(--transition);
    background:var(--color-bg);
    box-shadow: 0.5rem 0.2rem 0.5rem var(--color-primary) ;
}


@media screen and (max-width: 1024px){
    /* transition: var(--transition); */
    .contact__body>p{
        font-size: 1.2rem;
        padding-bottom: 2rem;
    }
    .contact__body{
        padding: 3rem;
        max-width: 70%;
        font-size: 0.95rem;

    }
    
}
  @media screen and (max-width: 600px){
 
    .contact__body>p{
        font-size: 1rem;
        padding-bottom: 2rem;
    }
    .contact__body{
        max-width: 100%;
        font-size: 0.85rem;

    }
    
    .secondary__header{
        font-size: 3rem;
    }
  }