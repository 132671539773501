.navbar {
  display: flex;
  align-items: center;
  column-gap: 3rem;
  width: 100%;
  opacity: 0.75;
  backdrop-filter: blur(10px);
  background: rgba(var(--color-bg), 0.5);
  height: 6rem;
  position: sticky;
  top: 0;
  transition-property: all;
  padding: 0 8rem;
  transition-duration: 800ms;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.nav__push {
  margin-left: auto;
}

.navbar.hide {
  top: -6rem;
  transition-duration: 800ms;
}

@media screen and (max-width: 1024px) {
  .navbar {
    height: 5rem;
    column-gap: 2rem;
    padding: 0 5rem;
  }
}
@media screen and (max-width: 600px) {
  .navbar {
    height: 4rem;
    column-gap: 0.5rem;
    padding: 0 1rem;
  }
}
